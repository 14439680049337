<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">ข้อมูลเพิ่มเติม</div>
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form" class="form"
          novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <div class="row">
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">ระดับการศึกษา (Education)</label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field as="select" name="edu_lv" class="form-control  form-control-solid"
                      @change="checkedu_lv($event)" v-model="data_register.edu_lv">
                      <option value="" selected disabled>โปรดเลือก</option>
                      <option value="มัธยมต้น">มัธยมต้น</option>
                      <option value="มัธยมปลาย">มัธยมปลาย</option>
                      <option value="ปวช">ปวช</option>
                      <option value="ปวส">ปวส</option>
                      <option value="ปริญาตรี">ปริญาตรี</option>
                      <option value="ปริญาโท">ปริญาโท</option>
                      <option value="ปริญญาเอก">ปริญญาเอก</option>
                      <option value="อื่นๆ">อื่นๆ (Other)</option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="edu_lv" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row mt-1" v-if="edu_status">
                    <Field type="text" name="edu_lv_other" class="form-control  form-control-solid"
                      placeholder="ระดับการศึกษา" v-model="data_register.edu_lv_other" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="edu_lv_other" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>

                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">อาชีพ (Profession)</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field as="select" name="job" class="form-control  form-control-solid" @change="checkjob($event)"
                      v-model="data_register.job">
                      <option value="" selected disabled>โปรดเลือก</option>
                      <option value="เจ้าของกิจการ">เจ้าของกิจการ (Bussiness Owner)</option>
                      <option value="พนักงานเอกชน">พนักงานเอกชน (Private company employee)</option>
                      <option value="ข้าราชการ / รัฐวิสาหกิจ">ข้าราชการ / รัฐวิสาหกิจ (Government Employee)</option>
                      <option value="ศิลปิน">ศิลปิน ศิลปิน(Artist)</option>
                      <option value="อาชีพอิสระ">อาชีพอิสระ (Freelance)</option>
                      <option value="อื่นๆ">อื่นๆ (Other)</option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="job" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row mt-1" v-if="job_status">
                    <Field type="text" name="job_other" class="form-control  form-control-solid" placeholder="อาชีพ"
                      v-model="data_register.job_other" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="job_other" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>


                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6">สนใจงานศิลปะประเภทไหน ? <br> What type of art are you interested in ?</label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field type="text" name="interes_art_type" class="form-control  form-control-solid"
                      placeholder="ประเภทงานศิลปะที่สนใจ" v-model="data_register.interes_art_type" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="interes_art_type" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>

                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6">ศิลปินที่ท่านชื่นชอบคือใคร? <br> Favorite artist ?</label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field type="text" name="love_artist" class="form-control  form-control-solid"
                      placeholder="ศิลปินที่ท่านชื่นชอบ" v-model="data_register.love_artist" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="love_artist" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>

                <div class="row mb-6">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">เคยสะสมงานศิลปะมาก่อนหรือไม่? <br>Have you ever owned any artworks ?</label>
                  <div class="col-12 mt-2">
                    <div class="form-check form-check-inline ">
                      <input class="form-check-input" type="radio" name="keep_art" value="never"
                        v-model="data_register.keep_art">
                      <label class="form-check-label fs-6" for="keep_art">
                        ไม่เคย (Never)
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input " type="radio" name="keep_art" value="beginner"
                        v-model="data_register.keep_art">
                      <label class="form-check-label  fs-6" for="keep_art">
                        พึ่งเริ่มสะสม (Beginner)
                      </label>
                    </div>


                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="keep_art" value="collector"
                        v-model="data_register.keep_art">
                      <label class="form-check-label  fs-6" for="keep_art">
                        สะสมมานานแล้ว (Collector)
                      </label>
                    </div>

                    <Field type="hidden" name="keep_art" v-model="data_register.keep_art" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="keep_art" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- {{data_register.event_source}} -->
                <div class="row mb">
                  <label
                    class="col-lg-12 col-form-label required fw-bold fs-6">รับทราบข้อมูลข่าวสารนิทรรศการจากช่องทางไหน? <br>How were you informed about this event?</label>


                  <div class="col-4 mt-2">
                    <div class="form-check form-check-inline ">
                      <input class="form-check-input" type="checkbox" name="event_source" value="Facebook"
                        v-model="data_register.event_source">
                      <label class="form-check-label fs-6" for="event_source">
                        Facebook
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input " type="checkbox" name="event_source" value="Instagram"
                        v-model="data_register.event_source">
                      <label class="form-check-label  fs-6" for="event_source">
                        Instagram
                      </label>
                    </div>


                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" name="event_source" value="Tiktok"
                        v-model="data_register.event_source">
                      <label class="form-check-label  fs-6" for="event_source">
                        Tiktok
                      </label>
                    </div>




                  </div>
                  <div class="col-4 mt-2">
                    <div class="form-check form-check-inline ">
                      <input class="form-check-input" type="checkbox" name="event_source" value="Line"
                        v-model="data_register.event_source">
                      <label class="form-check-label fs-6" for="event_source">
                        Line
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input " type="checkbox" name="event_source" value="Messenger"
                        v-model="data_register.event_source">
                      <label class="form-check-label  fs-6" for="event_source">
                        Messenger
                      </label>
                    </div>


                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" name="event_source" value="Acquaintance"
                        v-model="data_register.event_source">
                      <label class="form-check-label  fs-6" for="event_source">
                        Acquaintance
                      </label>
                    </div>

                  </div>
                  <div class="col-4 mt-2">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" name="event_source" value="Others"
                        v-model="data_register.event_source">
                      <label class="form-check-label  fs-6" for="event_source">
                        Others
                      </label>
                    </div>

                  </div>
                  <Field type="hidden" name="event_source" v-model="data_register.event_source" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="event_source" />
                    </div>
                  </div>
                </div>

                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6">ในนิทรรศการครั้งนี้ท่านมีความตั้งใจจะมาชมผลงานของศิลปินท่านไหนเป็นพิเศษ ? <br> In this event, have you got any artwork that you want to see in particular? which or whose.</label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field type="text" name="interes_art_favorite" class="form-control  form-control-solid"
                      placeholder="ตั้งใจจะมาชมผลงานของศิลปินท่านไหนเป็นพิเศษ" v-model="data_register.interes_art_favorite" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="interes_art_favorite" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>




              </div>
            </div>

          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  ลงทะเบียน
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import json from "@/service/DataStore.json";
import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {

    let edu_status = ref(false);
    let job_status = ref(false);
    const store = inject("store");
    const router = inject("router");
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { Register } = useRegister();
    const items = ref(json);
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const data_register = store.getters.getRegister;

    if (!data_register.event_source) {
      data_register.event_source = [];
    }


    const register = Yup.object().shape({
      job: Yup.string().required("กรุณาเลือก อาชีพ").label("อาชีพ"),
      job_other: Yup.string()
        .when("job", {
          is: (job) => job === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก อาชีพ"),
        })
        .label("อาชีพ"),
      edu_lv: Yup.string().required("กรุณาเลือก ระดับการศึกษา").label("ระดับการศึกษา"),
      edu_lv_other: Yup.string()
        .when("edu_lv", {
          is: (edu_lv) => edu_lv === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก ระดับการศึกษา"),
        })
        .label("ระดับการศึกษา"),
      //interes_art_type: Yup.string().required("กรุณากรอกประเภทงานศิลปะที่สนใจ"),
      //love_artist: Yup.string().required("กรุณากรอกประเภทงานศิลปะที่สนใจ"),
      keep_art: Yup.string().required("กรุณาเลือก 1 ข้อ"),
      event_source: Yup.array().min(1, "กรุณาเลือก 1 ข้อ")


    });


    const uniqueProvince = computed(() => {
      return items.value.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.province]: current,
        });
      }, {});
    });

    const uniqueAmphoe = computed(() => {
      let dd = items.value.filter(
        (item) => item.province === data_register.province
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.amphoe]: current,
        });
      }, {});
    });

    function checkjob(event) {
      if (event.target.value === "อื่นๆ") {
        job_status.value = true;
      } else {
        job_status.value = false;
      }
    }

    function checkedu_lv(event) {
      if (event.target.value === "อื่นๆ") {
        edu_status.value = true;
      } else {
        edu_status.value = false;
      }
    }

    const uniqueDistrict = computed(() => {
      let dd = items.value.filter(
        (item) => item.amphoe === data_register.amphoe
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.district]: current,
        });
      }, {});
    });

    function setZipcode(val) {
      let result = val;

      let data = items.value.filter(
        (item) =>
          item.district === result && item.amphoe === data_register.amphoe
      );
      data_register.zipcode = data[0].zipcode;
    }

    const back = () => {
      router.push({ name: "Register_1" });
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {

      store.dispatch(SET_REGISTER, values);


      console.log(store.getters.getRegister);

      Sconfirm("ยืนยัน การลงทะเบียน", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    return {
      back,
      onSubmitRegister,
      register,
      data_register,
      isLoading,
      color,
      uniqueProvince,
      uniqueAmphoe,
      uniqueDistrict,
      setZipcode,
      checkedu_lv,
      edu_status,
      job_status,
      checkjob
    };
  },
});
</script>

<style scoped>
.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
}



input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
