<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center" style="background-color: var(--color) !important; ">
          <img src="@/assets/logo.png" style="height: 100px;border-radius: 50%;" alt="..." />
          <div class="t1" style="margin: 5px; color: white;">แบบสอบถาม</div>
          <!-- <div class="t1" style="margin: 5px">เพื่อรับฟังความคิดเห็นต่อร่างแผนพัฒนาท้องถิ่น</div>
          <div class="t1" style="margin: 5px">เทศบาลนครนครศรีธรรมราช</div> -->
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">


              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">คำนำหน้า</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="fn" class="form-control  form-control-solid" @change="checkFn($event)"
                    v-model="data_register.fn">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="นาย">นาย</option>
                    <option value="นาง">นาง</option>
                    <option value="นางสาว">นางสาว</option>
                    <option value="อื่นๆ">อื่นๆ</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fn" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="fn_status">
                  <Field type="text" name="fn_other" class="form-control  form-control-solid" placeholder="คำนำหน้า"
                    v-model="data_register.fn_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fn_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ชื่อ-นามสกุล</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="fullname" class="form-control  form-control-solid" placeholder="ชื่อ-นามสกุล"
                    v-model="data_register.fullname" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fullname" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">เบอร์โทรศัพท์</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="tel" class="form-control  form-control-solid" placeholder="เบอร์โทรศัพท์"
                    v-model="data_register.tel" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="tel" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>


              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">อายุ (กรอกเฉพาะตัวเลข)</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="number" max="99" name="age" class="form-control  form-control-solid" placeholder="อายุ"
                    v-model="data_register.age" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="age" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>


              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">อาชีพ</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="job" class="form-control  form-control-solid" @change="checkjob($event)"
                    v-model="data_register.job">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="เจ้าของกิจการ">เจ้าของกิจการ</option>
                    <option value="พนักงานเอกชน">พนักงานเอกชน</option>
                    <option value="ข้าราชการ / รัฐวิสาหกิจ">ข้าราชการ / รัฐวิสาหกิจ</option>
                    <option value="อาชีพอิสระ">อาชีพอิสระ</option>
                    <option value="อื่นๆ">อื่นๆ </option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="job" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="job_status">
                  <Field type="text" name="job_other" class="form-control  form-control-solid" placeholder="อาชีพ"
                    v-model="data_register.job_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="job_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>


              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">คุณอยู่แถวไหน ?</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="live_zone" class="form-control  form-control-solid"
                    placeholder="สถานที่ใกล้เคียง" v-model="data_register.live_zone" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="live_zone" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>








              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="submit" class="button">ส่งแบบสอบถาม</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import moment from "moment";
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import json from "@/service/DataStore.json";
import useSweetalert from "@/service/sweetalert2";
import useRegister from "@/service/api/register";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {
    let job_status = ref(false);
    let edu_status = ref(false);
    let type_status = ref(false);
    let comment_status = ref(false);
    const items = ref(json);
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);
    const data_register = store.getters.getRegister;
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { Register } = useRegister();

    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const register = Yup.object().shape({
      fn: Yup.string().required("กรุณาเลือก คำนำหน้า").label("คำนำหน้า"),
      fn_other: Yup.string()
        .when("fn", {
          is: (fn) => fn === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก คำนำหน้า"),
        })
        .label("คำนำหน้า"),
      fullname: Yup.string().required("กรุณากรอก ชื่อ-นามสกุล").label("ชื่อ"),
      tel: Yup.string()
        .required("กรุณากรอกเบอร์โทรศัพท์")
        .matches(phoneRegExp, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
        .min(10, "เบอร์โทรศัพท์ไม่ครบ")
        .max(10, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"),
      job: Yup.string().required("กรุณาเลือก อาชีพ").label("อาชีพ"),
      job_other: Yup.string()
        .when("job", {
          is: (job) => job === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก อาชีพ"),
        })
        .label("อาชีพ"),
      live_zone: Yup.string().required("กรุณากรอกข้อมูล"),
      age: Yup.number().required("กรุณากรอกอายุ").max(99, "กรุณากรอกอายุให้ถูกต้อง"),
    });

    const uniqueProvince = computed(() => {
      return items.value.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.province]: current,
        });
      }, {});
    });
    function checkjob(event) {
      if (event.target.value === "อื่นๆ") {
        job_status.value = true;
      } else {
        job_status.value = false;
      }
    }
    function checktype(event) {
      if (event.target.value === "อื่นๆ") {
        type_status.value = true;
      } else {
        type_status.value = false;
      }
    }






    const monthNamesThai = ref([
      { id: "01", name: "มกราคม" },
      { id: "02", name: "กุมภาพันธ์" },
      { id: "03", name: "มีนาคม" },
      { id: "04", name: "เมษายน" },
      { id: "05", name: "พฤษภาคม" },
      { id: "06", name: "มิถุนายน" },
      { id: "07", name: "กรกฎาคม" },
      { id: "08", name: "สิงหาคม" },
      { id: "09", name: "กันยายน" },
      { id: "10", name: "ตุลาคม" },
      { id: "11", name: "พฤษจิกายน" },
      { id: "12", name: "ธันวาคม" },
    ]);

    // const monthNamesThai = ref([
    //   { id: "01", name: "January" },
    //   { id: "02", name: "February" },
    //   { id: "03", name: "March" },
    //   { id: "04", name: "April" },
    //   { id: "05", name: "May" },
    //   { id: "06", name: "June" },
    //   { id: "07", name: "July" },
    //   { id: "08", name: "August" },
    //   { id: "09", name: "September" },
    //   { id: "10", name: "September" },
    //   { id: "11", name: "November" },
    //   { id: "12", name: "December" },
    // ]);


    const setRange = () => {
      let year = moment().year() + 543;
      let value = [];
      let i;
      for (i = year; i >= year - 100; i--) {
        value.push(i);
      }
      return value;
    };
    const setday = (day, month, year) => {
      let y = year;
      let m = month;
      let d = day;
      return y + "-" + m + "-" + d;
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {

      values.id_line = store.getters.getAccessToken.accesstoken;

      store.dispatch(SET_REGISTER, values);
      // router.push({ name: "Register_3" });

      console.log(store.getters.getRegister);

      Sconfirm("ยืนยัน การส่งแบบสอบถาม", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ขอบคุณที่ร่วมแสดงความคิดเห็น", "success", "ตกลง").then(() => {
                  window.location.replace(`line://app/${process.env.VUE_APP_REGISTERST}`);
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    function checkFn(event) {
      if (event.target.value === "อื่นๆ") {
        fn_status.value = true;
      } else {
        fn_status.value = false;
      }
    }

    return {
      onSubmitRegister,
      register,
      checkFn,
      fn_status,
      iconMale,
      iconFemale,
      data_register,
      monthNamesThai,
      setday,
      setRange,
      uniqueProvince,
      edu_status,
      checktype,
      type_status,
      checkjob,
      job_status,
      comment_status,
      color,
      isLoading
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 20px !important;
  /* adjust the width of the image */
  height: 20px !important;
  /* adjust the height of the image */
  margin-right: 5px;
  /* add some space between the image and the label text */
}
</style>
